import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Config } from '../../common/config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import ImageList from '@material-ui/core/ImageList'
import ImageListItem from '@material-ui/core/ImageListItem'

class SearchOrder extends Component {
    constructor(props) {
        super(props);

        // this.orderNumber = props.match.params.orderNumber

        this.state = {
            orderNumber: '',
            isValidInputOrderNumber: false,
            data: [],
            isLoading: false,
            isPrinting: false,
            openAlert: false,
            alertTitle: '',
            alertMessage: '',
        };
    }

    onChangedOrderNumber = (data) => {
        var orderNumber = data.target.value
        if (orderNumber.length > 4) {
            orderNumber = orderNumber.slice(0, 4)
        }

        this.setState({
            orderNumber: orderNumber,
            isValidInputOrderNumber: orderNumber.length === 4
        })
    }

    handleAlertClose = () => {
        this.setState({
            openAlert: false,
        })
    }

    openAlertDialog = (title, message) => {
        this.setState({
            openAlert: true,
            alertTitle: title,
            alertMessage: message,
        });
    }

    onFocusInputOrderNumber = () => {
        if (this.state.selectedEntranceGroup !== 'entrance_method') {
            this.setState({
                selectedEntranceGroup: 'entrance_method',
            });
        }
    }

    isEmpty = (value) => {
        if (value === "" || value == null || value === undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
            return true
        } else {
            return false
        }
    };

    onSearchOrder = () => {
        const orderNumber = this.state.orderNumber

        if (orderNumber.isEmpty) {
            alert("주문번호 앞 4자리를 입력해 주세요.");
            return
        }

        this.findOrders(orderNumber)
    }

    async findOrders(orderNumber) {
        let url = Config.api.base_url + "order/find-package?orderNumber=" + orderNumber;

        try {
            this.setState({
                isLoading: true,
            });
            const response = await fetch(url, {
                method: "GET",
                crossDomain: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            })

            if (!response.ok) {
                throw response
            }
            const body = await response.json()

            this.setState({
                isLoading: false,
                data: body,
            });
        } catch (err) {
            console.log(err)
            this.setState({
                isLoading: false,
            });

            let body = {}
            if (err.json) {
                body = await err.json()
                alert(body.message)
            } else {
                console.log(err)
                alert('주문정보를 조회할 수 없습니다.')
            }

        }
    }

    inputOnKeyPress = (e) => {
        console.log(e.key)
        if (e.key === 'Enter') {
            this.onSearchOrder()
        }
    }

    openURLInNewTab(href) {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            href: href,
        }).click();
    }

    printWaybill = (order_number) => {
      let url = "http://localhost:30500"
      this.setState({ isPrinting: true })

      let order = null
      for(const o of this.state.data) {
        if(o.orderNumber === order_number) {
          order = o
        }
      }

      if(order === null) {
        alert("출력할 주문을 찾을 수 없습니다.")
        return false
      }
      fetch(url, {
        method: "POST",
        crossDomain: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify([order.labelInfo])
      })
      .then(resp => {
        if (resp.ok) { return resp.json() }
        else { throw resp }
      })
      .then(body => {
        if("version" in body) {
          alert("성공적으로 전송했습니다.\n프린터에서 출력되지 않는 경우 프린터 연결 및 연결된 프린터가 선택되어 있는지 확인해 주세요.")
        } else {
          alert("프린터 프로그램 오류입니다.")
        }
        this.setState({ isPrinting: false })
      })
      .catch(err => {
        this.setState({ isPrinting: false })
        if(err.constructor === TypeError) {
          alert("핑퐁 프린터 프로그램을 찾을 수 없습니다.\n핑퐁 프린터 프로그램이 실행 중인지 확인해 주세요.")
        } else {
          alert(err)
        }
      })
    }

    render() {
        document.querySelector("title").innerText = "핑퐁 주문정보 조회"

        return (
            <div>
                <header>
                    <div className="title">핑퐁 당일배송 주문 조회 시스템</div>
                </header>
                <main>
                    <div className='valid_order'>

                        <div className='entrance_container'>
                            <input className="input_box"
                                id="input_entrance_method"
                                type="text"
                                placeholder="주문번호 앞 4자리"
                                value={this.state.orderNumber}
                                onFocus={this.onFocusInputOrderNumber}
                                onChange={this.onChangedOrderNumber}
                                onKeyPress={this.inputOnKeyPress}
                            />
                            {
                                this.state.isLoading === true ?
                                    <div className="spinner_container"><CircularProgress className="spinner" size='50px' /></div>
                                    : <Button
                                        className='search_order_button'
                                        type='button'
                                        variant="contained"
                                        disabled={!this.state.isValidInputOrderNumber}
                                        onClick={this.onSearchOrder}
                                    ><div style={{ fontSize: '20pt' }}>검색</div>
                                    </Button>
                            }

                        </div>
                    </div>
                    <div class="card_container">
                        {
                            this.state.data.length ?
                                this.state.data.map((data, d_index) => {
                                    let brandName = data.brandName !== undefined && data.brandName.length > 0 ? `(${data.brandName})` : '';
                                    let scanned = data.hubScannedTime > 0 ? '입고완료' : '입고대기'
                                    return (
                                        <div>
                                            <Card variant="outlined">
                                              <div className="order_number" style={{height: "80px"}}>
                                                    <div style={{ float: "left", height: "80px", width: "calc(100% - 400px)", paddingLeft: "200px" }}><b>{data.orderNumber}</b>({scanned})</div>
                                                {this.state.isPrinting===true?
                                                  <div style={{float: "left", height: "40px", width: "200px", backgroundColor: "#ffffff", borderRadius: "5px", cursor: "pointer"}}><div style={{margin: "5px 80px 0 80px"}} className="spinner_container"><CircularProgress className="spinner-inline" size="30px"/></div></div>:
                                                  <div style={{float: "left", height: "40px", lineHeight: "40px", fontSize: "14pt", width: "200px", backgroundColor: "#ffffff", borderRadius: "5px", cursor: "pointer"}} onClick={() => this.printWaybill(data.orderNumber)}>송장 출력하기</div>}
                                                </div>
                                                {data.rackNumber !== undefined ? <div className="rack_number"><b>{data.rackNumber}번</b></div> : <div></div>}
                                                <div className="sender_info">{data.sender}{brandName}</div>
                                                <div className="address">{data.destinationDong}({data.destinationRoadname})</div>
                                                {data.imageUrls && data.imageUrls.length > 0 ?
                                                    <ImageList
                                                        cols={3}
                                                        gap={4}
                                                        rowHeight={500}
                                                    >
                                                        {data.imageUrls.map((item) => (
                                                            <ImageListItem key={item} style={{ cursor: "pointer" }} onClick={() => this.openURLInNewTab(item)}>
                                                                <img
                                                                    key={item}
                                                                    src={item}
                                                                    alt={'Order Item'}
                                                                />
                                                            </ImageListItem>
                                                        ))}
                                                    </ImageList>
                                                    : <div></div>}
                                            </Card>
                                            <div style={{ height: '20px' }}></div>
                                        </div>

                                    )
                                }) : <></>
                        }
                    </div>
                </main >
                <Dialog
                    open={this.state.openAlert}
                    onClose={this.handleAlertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.alertTitle == null ? '' : this.state.alertTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>{this.state.alertMessage}</div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAlertClose}>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default SearchOrder;